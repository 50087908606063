import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/AIDubbing',
    name: 'AIDubbing',
    component: () => import('../views/AIDubbing.vue')
  },
  {
    path: '/artificialDubbing',
    name: 'artificialDubbing',
    component: () => import('../views/artificialDubbing.vue')
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('../views/personalCenter.vue')
  },
  {
    path: '/personDetail',
    name: 'personDetail',
    component: () => import('../views/personDetail.vue')
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
