import axios from "axios";

const baseUrl = "https://crm.jisudonghua.com/voicetransferapis/"
const errCode = [0, 401, 402]


axios.interceptors.request.use(config => {
      config.url = config.url.replace('http://', 'https://');
      // // 后端没配 所以用form-data
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      let token = localStorage.getItem("token")
      if(token){
        config.headers['token'] = token;
      }
    return config;
  });
// 添加响应拦截器
axios.interceptors.response.use(
    response => {
      // if(errCode.includes(response.data.code)){
      //   localStorage.removeItem("token")
      //   localStorage.removeItem("userInfo")
      //   sessionStorage.setItem("loginFailure","true")
      //   location.href = location.origin
      // }
      // 请求成功，直接返回数据
      return response;
    },
    error => {
     // 响应错误处理
      if (error?.response) {
        console.log(error.response);
        if( errCode.includes(error.response.data.code)){
          localStorage.removeItem("token")
          localStorage.removeItem("userInfo")
          sessionStorage.setItem("loginFailure","true")
          location.href = location.origin
        }
        
      }
    }
  );


  function postJSONData(url, params) {
    return axios.post(url, params, {
        headers: {
        'Content-Type': 'application/json'
        }
    });
    }
    
    function postFormData(url, params) {
    let forms = new FormData();
    for (const key in params) {
        forms.append(key, params[key]);
    }
    return axios.post(url, forms, {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    }

// 获取短信验证码
export const sendSMS = (params) => {
  return  postJSONData(baseUrl + "sms/send",params)
}

// 手机号注册
export const mobileRegister = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/mobileRegister",params)
}

// 手机号密码登录
export const mobileLogin = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/mobileLogin",params)
}

// 手机号密码登录
export const changeMobile = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/changeMobile",params)
}

// 手机号密码登录
export const bindWx = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/bindWx",params)
}

// 新用户扫码后补充手机号和密码
export const fillProfile = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/fillProfile",params)
}

// 重置密码
export const resetPwd = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/resetPwd",params)
}

// 用户退出登录
export const logOut = (params) => {
  return  postJSONData(baseUrl + "wxauthorization/logOut",params)
}


// 获取主播列表
export const getTypeList = (params) => {
    return  axios.get(baseUrl + "texttosound/getTypeList",{
      params: params
  })
}
// 主播查询
export const queryAnchor = (params) => {
    return  axios.get(baseUrl + "texttosound/getVoiceList",{
      params: params
  })
}

// 文字转语音
export const ttsPost = (params) => {
    return  postJSONData(baseUrl + "texttosound/requestLongTts4Post",params)
}

// 文字转语音轮询结果
export const ttsPostTask = (params)=>{
    return postJSONData(baseUrl + "texttosound/waitLoop4Complete",params)
}
// 获取用户上传的背景音乐列表
export const getUserBgm = (params) => {
    return  axios.get(baseUrl + "texttosound/getMusicListByUserId",{
      params: params
  })
}

// 背景音乐上传
export const uploadBgm = (params)=>{
    return postFormData(baseUrl + "texttosound/uploadBackgroundMusic",params)
}

// 获取草稿/作品
export const getText = (params) => {
    return  axios.get(baseUrl + "texttosound/getPortfolioOrDraftList",{
      params: params
  })
}

// 保存草稿/作品
export const saveText = (params) => {
    return  postJSONData(baseUrl + "texttosound/saveOrEditDraft",params)
}

// 删除草稿/作品
export const deleteText = (params) => {
    return  postJSONData(baseUrl + "texttosound/deleteDraft",params)
}

// 获取token
export const authAccessToken = (params) => {
    return  postJSONData(baseUrl + "wxauthorization/authAccessToken",params)
}


// 获取真人配音列表
export const getTeamMembersList = (params) => {
  return  axios.get(baseUrl + "texttosound/getTeamMembersList",{
    params: params
})
}

// 真人配音获取详细作品列表
export const getWorksByMemberId = (params) => {
  return  axios.get(baseUrl + "texttosound/getWorksByMemberId",{
    params: params
})
}




